import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"

import Pagination from 'react-bootstrap/Pagination';
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { connect } from 'react-redux'

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import MenuGlobal from '../components/menu'
import FilterPorfolioGeneral from '../components/portfolio/filterPortfolioGeneral'
import FilterPorfolioGeneralMobile from '../components/portfolio/filterPortfolioGeneralMobile'
import ProjectsDisplay from '../components/portfolio/projectsDisplay'
import ProjectsDisplayMobile from '../components/portfolio/projectsDisplayMobile'
import SpinnerLoader from "../components/spinnerLoader";

import { splitToNChunks, filterByKeyword, filterByClient } from '../custom/functions'

import { motion } from "framer-motion"

import '../sass/portfolio.scss';


const PortfolioPage = ({ data, location, popupCTA }) => {

  // all projects with no filter but already filtering by current language
  const dataProjectsWithNoFilter = data.allProjects.edges[0].node.projetos.nodes
  const projectsPerPage = 4
  var length = dataProjectsWithNoFilter.length

  const [projectsToShow, setProjectsToShow] = useState(splitToNChunks(dataProjectsWithNoFilter, projectsPerPage))
  const [keywordToSearch, setKeywordToSearch] = useState('')
  const [clientToSearch, setClientToSearch] = useState('')
  const [pagination, setPagination] = useState(length / projectsPerPage)
  const [paginationAux, setPaginationAux] = useState(null)
  const [page, setPage] = useState(1)
  const [notFound, setNotFound] = useState(false)

  const [loadingProjects, setLoadingProjects] = useState(false)

  const breakpoints = useBreakpoint();
  const { search } = location;
  const { t } = useTranslation()
  const dataGlobal = t("global", { returnObjects: true })
  const dataPortfolio = t("portfolio", { returnObjects: true })

  const myRef = useRef(null)
  const myRefScrollTo = useRef(null)


  /**
   * 1. Retrieves user input
   * 2. Constructs new URL (Based on the presence of keyword or client variables)
   * 3. Updates page URL with page number (Checks if the current URL has page: 
   * YES - add "page" with the number and "&" as a separator. 
   * NOT - adds "page" with the number variable using "?" as a separator.)
   * 4. Updates page content and history
   */
  function updatePage(number) {
    var newurl = ''
    var newurlAfter = ''

    if (keywordToSearch)
      newurlAfter = newurl + '?keyword=' + keywordToSearch
    else if (clientToSearch)
      newurlAfter = newurl + '?client=' + clientToSearch

    var pageUrl = ''
    if (typeof window !== 'undefined') {
      if (window.location.pathname.includes('&page=') || window.location.pathname.includes('?page=')) {
        if (newurlAfter)
          pageUrl = '&'
        else
          pageUrl = "?"
        newurl = window.location.protocol + "//" + window.location.host + newurlAfter + pageUrl + 'page=' + number
      }
      else {
        pageUrl = ''
        if (newurlAfter)
          pageUrl = '&'
        else
          pageUrl = "?"
        newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + newurlAfter + pageUrl + 'page=' + number
      }
      setPage(parseInt(number))
      window.history.pushState({ path: newurl }, '', newurl);
      location.search = newurl

      if (typeof window !== 'undefined') {
        if (location.search !== '')
          window.scrollTo(0, window.innerHeight);
        else
          window.scrollTo(0, 0);
      }
    }

  }

  /** 
* When page loads, define pagination based on non filtered projects
* split the projects per page
* set atual page as 1
*/
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (location.search !== '')
        window.scrollTo(0, window.innerHeight);
      else
        window.scrollTo(0, 0);
    }

    if (!keywordToSearch && !clientToSearch) {
      setPagination(length / projectsPerPage)
      var paginationAux = []
      paginationAux = splitToNChunks(dataProjectsWithNoFilter, projectsPerPage)
      setProjectsToShow(paginationAux)
      setPage(1)
    }
  }, []);

  /**
  * Pagination logic, dividing projects by page
  */
  useEffect(() => {
    var number = 1
    var arrayAux = []

    for (number = 1; number <= Math.ceil(pagination); number++) {
      arrayAux.push(
        <Pagination.Item key={number} onClick={(event) => updatePage(parseInt(event.target.innerText))} className={number === page ? "description IRegular active" : "description IRegular"}>
          {number}
        </Pagination.Item>);

      setPaginationAux(arrayAux)
    }

  }, [page, pagination, updatePage]);


  /**
   * get parameters from url (service, subservice, page and brand)
   * logic: OR has service and subservice OR brand
   * check which group is selected (1- service and subservice, 2- brand)
   * filter projects by group selected
   * get page to do pagination 
   * this method is called everytime a new page is loaded, or the url is changed (including filter selection)
   */
  useEffect(() => {
    // loading appear
    setLoadingProjects(true)

    let params = new URLSearchParams(search);
    var keywordAux = params.get("keyword")

    var clientAux = params.get("client")

    var pageAux = params.get("page")

    if (pageAux) {
      setPage(pageAux)
    }

    if (keywordAux !== '') {
      setKeywordToSearch(keywordAux)

      filterByKeyword(dataProjectsWithNoFilter, setProjectsToShow, projectsPerPage, setPagination, setPage, location, setKeywordToSearch,
        setClientToSearch, setNotFound, keywordAux)

    } else if (clientToSearch !== '') {
      setClientToSearch(clientAux)

      filterByClient(dataProjectsWithNoFilter, setProjectsToShow, projectsPerPage, setPagination, setPage, location, clientAux)

    }

  }, [pagination, search]);


  /** 
 * listening projects AND page
 * show projects & hide Loading
 */
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingProjects(false)
    }, 500)
    if (typeof window !== 'undefined') {
      if (location.search !== '')
        window.scrollTo(0, window.innerHeight);
      else
        window.scrollTo(0, 0);
    }

    return () => clearTimeout(timeout)
  }, [projectsToShow, page, notFound, keywordToSearch]);



  return (

    <Layout mobile={useBreakpoint().mobile} post={null} data={dataGlobal} removeCTA={true} page={dataPortfolio.page} seo={dataPortfolio.seo}>

      {breakpoints.mobile ?
        <>
          <MenuGlobal video src="https://news.invisual.pt/wp/wp-content/uploads/2024/11/Portfolio-Mobile_.mp4"
            global={dataGlobal} textstatic={dataPortfolio} mobile centered={true}
            location={location} />
          <SmoothScroll>
            <div className="bg-white container-main-general-portfolio" ref={myRef}>

              <FilterPorfolioGeneralMobile allProjects={dataProjectsWithNoFilter} areas={dataPortfolio.areas} clients={dataPortfolio.clients} setProjects={setProjectsToShow}
                projectsPerPage={projectsPerPage} setPagination={setPagination} setPage={setPage} location={location} setClientToSearch={setClientToSearch}
                setKeywordToSearch={setKeywordToSearch} setNotFound={setNotFound} clientToSearch={clientToSearch} keywordToSearch={keywordToSearch}
                myRef={myRefScrollTo} />


              {loadingProjects ?
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                  className="h-100 container-spinner container-margin"
                >
                  <SpinnerLoader />
                </motion.div>
                :
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  {notFound ?
                    <div className="container-margin">
                      <h3 className="fs-3 ILightBeta">{dataPortfolio.notFound}</h3>
                    </div>
                    :
                    <>
                      <ProjectsDisplayMobile projects={projectsToShow[page - 1]} />

                      <div className="container-margin justify-content-center d-flex pb-0 pt-0 m-auto container-pagination">
                        <Pagination>
                          {page >= 2 &&
                            <Pagination.First onClick={() => updatePage(1)} />
                          }
                          {paginationAux}
                          {(page < projectsToShow.length) &&
                            <Pagination.Last onClick={() => updatePage(projectsToShow.length)} />
                          }
                        </Pagination>
                      </div>
                    </>
                  }
                </motion.div>
              }

            </div>

            <div className="container-scroll-heigth bg-white" />

          </SmoothScroll>
        </>
        :
        <>
          <MenuGlobal video src="https://news.invisual.pt/wp/wp-content/uploads/2024/11/Portfolio-Desktop_.mp4"
            global={dataGlobal} textstatic={dataPortfolio}
            location={location} />

          <SmoothScroll>

            <div className="bg-white" ref={myRef} id="scrollTo">

              <FilterPorfolioGeneral allProjects={dataProjectsWithNoFilter} areas={dataPortfolio.areas} clients={dataPortfolio.clients} setProjects={setProjectsToShow}
                projectsPerPage={projectsPerPage} setPagination={setPagination} setPage={setPage} location={location} setClientToSearch={setClientToSearch}
                setKeywordToSearch={setKeywordToSearch} setNotFound={setNotFound} clientToSearch={clientToSearch} keywordToSearch={keywordToSearch}
                myRef={myRefScrollTo} />

              {loadingProjects ?
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                  className="h-100 container-spinner container-margin"
                >
                  <SpinnerLoader />
                </motion.div>
                :
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  {notFound ?
                    <div className="container-margin">
                      <h3 className="fs-3 ILightBeta">{dataPortfolio.notFound}</h3>
                    </div>
                    :
                    <>
                      <ProjectsDisplay projects={projectsToShow[page - 1]} />

                      <div className="container-margin justify-content-center d-flex pb-0 pt-0 m-auto container-pagination">
                        <Pagination>
                          {page >= 2 &&
                            <Pagination.First onClick={() => updatePage(1)} />
                          }
                          {paginationAux}
                          {(page < projectsToShow.length) &&
                            <Pagination.Last onClick={() => updatePage(projectsToShow.length)} />
                          }
                        </Pagination>
                      </div>
                    </>
                  }
                </motion.div>
              }

            </div>

            <div className="container-scroll-heigth bg-white" />
            <div className="container-scroll-heigth-small bg-white" />

          </SmoothScroll>
        </>

      }

    </Layout>)
}

export default connect(state => ({
  popupCTA: state.app.popupCTA
}), null)(PortfolioPage)

export const pageQuery = graphql`
      query($language: String!) {
        locales: allLocale(filter: {ns: {in: ["global", "portfolio", "work"]}, language: {eq: $language}}) {
        edges {
        node {
        ns
            data
      language
          }
        }
    }
      allProjects: allWpLinguagem(filter: {slug: {eq: $language}}) {
        edges {
        node {
        projetos {
        nodes {
        featuredImage {
        node {
        sourceUrl
      }
              }
      keywords {
        nodes {
        slug
                  name
                }
              }
      clientes {
        nodes {
        name
                  slug
                }
              }
      projetos {
        name
                shortDescription
      slug
      url
      colors {
        firstcolor
                  secondcolor
                }
      blocks {
        anchor
                  classname
      number
      itens {
        link
                    imagesrc {
        nodes {
        sourceUrl
                        altText
      mediaItemUrl
                      }
                    }
                  }
      cols {
        text
                    titleandtext {
        text
                      title
                    }
                  }
                }
              }
            }
          }
      slug
        }
      }
    }
  }
      `